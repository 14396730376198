import { useState, useEffect } from "react"

export function useIsDesktop(breakpoint: number): boolean {
    const [isDesktop, setIsDesktop] = useState(false)


	useEffect(() => {
        const mediaQuery = window.matchMedia(`(min-width: ${breakpoint}px)`)
        setIsDesktop(mediaQuery.matches)

        const handleMediaQueryChange = (event: MediaQueryListEvent) => {
            setIsDesktop(event.matches)
        }

        mediaQuery.addEventListener("change", handleMediaQueryChange)

        return () => {
            mediaQuery.removeEventListener("change", handleMediaQueryChange)
        }
    }, [breakpoint])

    return isDesktop
}
