import React, { useEffect, useRef, useState } from "react"
import { graphql, PageProps, Link } from "gatsby"

import { ArrowRight } from "react-feather"
import ScrollIntoView from "react-scroll-into-view"

import Layout from "../components/layout"
import { Button } from "../components/ui"

import ItemServices from "../components/item-services"
import ItemBlog from "../components/item-blog"
import { Form, Description as ContactDescription } from "../components/contact"
import { IndexPageQuery } from "./__generated__/IndexPageQuery"

import { sortServices } from "../components/utils"

export default ({ data, location }: PageProps<IndexPageQuery>) => {
    const siteData = data.site.siteMetadata

	// order data by serviceListOrder using frontmatter.title
	sortServices(data.services.edges)

    const servicesList = data.services.edges.map((item, _) => (
        <ItemServices
            data={item.node}
            key={`p-item-index-${item.node.id}`}
            even={(_ + 1) % 2 === 0}
        />
    ))

    const blogList = data.blog.edges.map(item => (
        <ItemBlog data={item.node} key={`b-item-index-${item.node.id}`} />
    ))

    return (
        <Layout
            front={true}
            seo={{
                title: "Home",
                description: siteData.description,
            }}
            navPlaceholder={false}
            location={location}
        >
            <Wall data={siteData} />
            {siteData.about !== "" && <About data={siteData.about} />}
            <div className="px-4 lg:px-0" id="services">
                {servicesList}
            </div>
            {/* <Blog>{blogList}</Blog> */}
            <Contact data={siteData.contact} />
        </Layout>
    )
}

const Wall = ({ data }) => {
    const wall = useRef(null)

    const twoColumnWall = data.twoColumnWall

    const [state, changeState] = useState({
        loaded: false,
        supportsBlend: false,
    })

    useEffect(() => {
        if (window.CSS && !state.loaded) {
            if (CSS.supports("mix-blend-mode", "screen")) {
                wall.current.classList.add("supports-blend")
                changeState({
                    loaded: true,
                    supportsBlend: true,
                })
            }
        }
    }, [state.loaded])

    let spanAttrs: Partial<{ style: unknown }> = {}

    if (!twoColumnWall && data.titleImage) {
        spanAttrs.style = {
            backgroundImage: `url('${data.titleImage}')`,
        }
    }

    const innerComponents = (
        <React.Fragment>
            <div className="title bg-bg">
                <h1
                    className={`text-6xl relative lg:text-7xl mt-20 mb-10 leading-tight ${
                        data.capitalizeTitleOnHome ? "uppercase" : ""
                    }`}
                >
                    <span {...spanAttrs}></span>
                    {data.title}
                </h1>
            </div>
            <p className="text-lg lg:text-xl text-color-2 pt-4 lg:pt-0">
                {data.introTag}
            </p>
            <p className="text-base lg:text-lg mt-4">{data.description}</p>
            <ScrollIntoView selector="#services">
                <Button
                    title="SEE ALL OUR SERVICES"
                    type="button"
                    iconRight={<ArrowRight />}
                />
            </ScrollIntoView>
        </React.Fragment>
    )

    if (twoColumnWall) {
        return (
            <div
                className="wall h-screen flex relative justify-center"
                ref={wall}
            >
                <div className="flex-1 lg:block absolute lg:relative w-full h-full top-0 left-0">
                    <div
                        className="absolute left-0 top-0 w-full h-full lg:hidden"
                        style={{
                            background: "rgba(0,0,0,.75)",
                        }}
                    ></div>
                    <img
                        src={data.titleImage}
                        alt=""
                        className="h-full lg:w-full object-cover"
                    />
                </div>
                <div className="flex-1 text-center p-3 relative z-10 lg:text-left lg:pl-8 text-white lg:text-color-default">
                    {innerComponents}
                </div>
            </div>
        )
    }

    return (
        <div
            className="wall h-screen flex flex-col justify-center items-center text-center"
            ref={wall}
        >
            {innerComponents}
        </div>
    )
}

const About = ({ data }) => {
    return (
        <div className="boxed">
            <div className="px-4 py-20  lg:py-40 lg:px-0">
                <h2 className="text-color-1 text-center font-black text-5xl lg:text-6xl">
                    About
                </h2>
                <p className="mt-5 text-lg">
                    Alderwood Tree Care Ltd is a small team of qualified
                    professional climbing arborists based in Chorlton, South
                    Manchester. Established in 2005 and owned by Matt Brereton
                    and Seth Rolph, we have a long and reliable history of
                    providing high quality tree services to satisfied customers
                    across Manchester, Salford, Stockport and parts of Cheshire.
                </p>
                <p className="mt-5 text-lg">
                    We have over{" "}
                    <a href="https://www.google.com/search?q=Alderwood+Tree+Care&ei=oi8vZNjGG8KQ8gK6-IeACg&ved=0ahUKEwjYn-KEkJb-AhVCiFwKHTr8AaAQ4dUDCA4&uact=5&oq=Alderwood+Tree+Care&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIOCC4QgAQQxwEQrwEQ6gQyBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yCAgAEBYQHhAPMggIABCKBRCGAzIICAAQigUQhgMyGQguEIAEEMcBEK8BEOoEENwEEN4EEOAEGAE6CAgAEKIEELADSgQIQRgBUIYHWIYHYPULaARwAHgAgAF2iAF2kgEDMC4xmAEAoAECoAEByAEDwAEB2gEGCAEQARgU&sclient=gws-wiz-serp#lrd=0x487bad8b61db259d:0xc1d0ec3538bfd8bf,1,,,,">
                        150 five star reviews
                    </a>{" "}
                    on Google and continue to build on our highly rated
                    reputation. We work for domestic and commercial customers
                    providing a range of services including:
                </p>
                <ul className="list-disc text-lg mt-5">
                    <li>Tree removal</li>
                    <li>Crown reductions</li>
                    <li>Crown lifts</li>
                    <li>Crown thinning</li>
                    <li>Fruit tree pruning</li>
                    <li>Hedge reductions and trimming</li>
                    <li>Ivy removal</li>
                    <li>Fire wood supplies</li>
                    <li>Air dried hardwood planks</li>
                    <li>Tree and hedge planting</li>
                    <li>Conifer reductions, trimming and shaping</li>
                </ul>
                <p className="mt-5 text-lg">
                    If you would like to arrange a free, no obligation, quote
                    please use our <Link to="/contact">contact page</Link>.
                    Alternatively you can call either Matt on{" "}
                    <a href="tel:07747166423">07747166423</a> or Seth on{" "}
                    <a href="tel:07449329039">07449329039</a> but please leave a
                    message as we might be up a tree
                </p>
            </div>
        </div>
    )
}

const Blog = ({ children }) => {
    return (
        <div className="container mx-auto px-0">
            <div className="pt-20 pb-10 text-center lg:pt-40 lg:pb-20">
                <h2 className="text-color-1 font-black text-5xl lg:text-6xl">
                    Blog
                </h2>
            </div>
            <div className="flex flex-wrap">{children}</div>
        </div>
    )
}

const Contact = ({ data }) => {
    const hasContactForm = data.api_url
    return (
        <div className="container mx-auto">
            <div className="pt-20 pb-10 lg:pt-40 lg:pb-20 text-center">
                <h2 className="text-color-1 font-black text-5xl lg:text-6xl">
                    Contact
                </h2>
            </div>
            <div className="flex flex-wrap pb-40">
                {hasContactForm && (
                    <div className="w-full lg:w-1/2 px-4 lg:pl-2 lg:pr-6">
                        <Form api={data.api_url} />
                    </div>
                )}
                <div
                    className={`w-full ${
                        hasContactForm ? "lg:w-1/2" : "lg:w-2/3 mx-auto"
                    } px-6 pt-8`}
                >
                    <ContactDescription data={data} />
                </div>
            </div>
        </div>
    )
}

export const query = graphql`
    query IndexPageQuery {
        site: site {
            siteMetadata {
                title
                description
                capitalizeTitleOnHome
                titleImage
                ogImage
                twoColumnWall
                introTag
                description
                about
                contact {
                    api_url
                    description
                    mail
                    phone
                    address
                }
                social {
                    name
                    url
                    icon
                }
            }
        }
        services: allMdx(
            filter: { fields: { sourceName: { eq: "services" } } }
            limit: 8
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        description
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
        blog: allMdx(
            filter: { fields: { sourceName: { eq: "blog" } } }
            limit: 6
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        description
                        date(formatString: "DD MMMM YYYY")
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`
